<template>
  <div class="project-resource" v-loading="loading">
    <el-tree
      ref="tree"
      :data="tree"
      :render-content="renderContent"
      node-key="id"
      class="cus-tree-render"
      check-strictly
      show-checkbox
      default-expand-all
    ></el-tree>
    <base-dialog :title="dialogTitle" v-model="showDialog" @ok="saveData">
      <base-form
        :init-value="false"
        v-model="formData"
        ref="form"
        :rules="rules"
        :form-items="formItems"
      ></base-form>
    </base-dialog>
  </div>
</template>
<script>
export default {
  name: 'project-resource',
  props: {
    project: Object
  },
  data() {
    return {
      loading: false,
      showDialog: false,
      dialogTitle: '添加资源',
      formData: {},
      cuurEditData: {}, //当前修改的项
      formItems: [
        { label: '资源名称', prop: 'name', value: '' },
        {
          label: '父级资源',
          prop: 'pid',
          component: 'resource-cascader',
          bind: { options: [], nid: '' }
        },
        {
          label: '资源权限',
          prop: 'roles',
          component: 'role-state-list',
          bind: { projectid: this.project.id, resid: '', ref: 'roleStateList' }
        }
      ],
      rules: {
        name: [
          { required: true, message: '请输入资源名称', trigger: 'blur' },
          {
            min: 1,
            max: 50,
            message: '长度在 1 到 50 个字符',
            trigger: 'blur'
          }
        ],
        pid: [{ required: true, message: '请选择父级资源', trigger: 'blur' }]
      },
      tree: []
    }
  },
  computed: {
    form() {
      return this.$refs.form
    }
  },
  methods: {
    getProjectResource() {
      this.loading = true
      this.$post('/resource/find', {
        PageIndex: 1,
        PageSize: 9999,
        project: JSON.stringify({
          Field: 'project',
          ComparePattern: 1,
          Values: [this.project.id]
        })
      })
        .then((resp) => {
          this.loading = false
          if (resp.data.code == 0) {
            this.tree = this.deepTreeData(resp.data.data.Data, '0')
          } else {
            this.$message(resp.data.msg || '获取项目资源失败')
          }
        })
        .catch((error) => {
          this.loading = false
          this.$message('获取项目资源发生异常=>' + error)
        })
    },
    deepTreeData(rows, pid) {
      var ret = []
      if (Array.isArray(rows)) {
        for (var row of rows) {
          if (row.pid == pid) {
            var item = Object.assign(
              { label: row.name, value: row.id, disabled: true },
              row
            )
            var children = this.deepTreeData(rows, item.id)
            if (children && children.length > 0) {
              item.children = children
            }

            ret.push(item)
          }
        }
      }
      return ret
    },
    renderContent(h, { node, data, store }) {
      return h(
        'resource-tree-item',
        { props: { value: data }, on: { edit: this.edit, del: this.del } },
        []
      )
    },
    beginAdd(data) {
      this.formItems[1].bind.nid = ''
      this.formItems[2].bind.resid = ''
      this.showDialog = true
      this.dialogTitle = '添加资源'
      setTimeout(() => {
        this.formData = {
          domain: this.project.domain,
          project: this.project.id,
          name: '',
          roles: [],
          pid: data && data.id ? data.id : '0'
        }
        this.$refs.form.$refs.roleStateList.doRefresh() //需要每次触发计算roles字段
      }, 100)
    },
    edit(data) {
      this.formItems[1].bind.nid = data.id
      this.formItems[2].bind.resid = data.id
      this.dialogTitle = '修改资源'
      this.showDialog = true
      setTimeout(() => {
        this.cuurEditData = data
        this.formData = Object.assign({ roles: [] }, data)
        this.$refs.form.$refs.roleStateList.doRefresh() //需要每次触发计算roles字段
      }, 100)
    },
    del(data) {
      var items = this.getRemoveItems(data)
      if (
        confirm(
          '确定删除"' +
            data.name +
            '"' +
            (items.length > 1 ? '，以及其下的子资源?' : '?')
        )
      ) {
        this.$post('/resource/removes', {
          parameters: JSON.stringify(items)
        }).then((resp) => {
          if (resp.data.code == 0) {
            this.removeTreeItem(data, true)
          } else {
            this.$message(resp.data.msg || '删除失败')
          }
        })
      }
    },
    saveData() {
      this.form.validate((flag, fields) => {
        if (flag) {
          var isAdd = !this.formData.id
          this.$post('/resource/' + (isAdd ? 'addAndRole' : 'updateAndRole'), {
            parameters: JSON.stringify(this.formData)
          }).then((resp) => {
            if (resp.data.code == 0) {
              this.showDialog = false
              if (isAdd) {
                this.addTreeItem(resp.data.data)
              } else {
                this.updateTreeItem(resp.data.data)
              }
            } else {
              this.$message(resp.data.msg || '操作失败')
            }
          })
        } else {
          this.$message('请填写完整')
        }
      })
    },
    addTreeItem(item) {
      var data = Object.assign(
        { label: item.name, value: item.id, disabled: true },
        item
      )
      if (data.pid == '0') {
        this.tree.push(data)
        return true
      } else {
        return this.foreachTreeItem(this.tree, (row, rows) => {
          if (row.value == item.pid) {
            if (Array.isArray(row.children)) {
              row.children.push(data)
            } else {
              this.$set(row, 'children', [data])
            }
            return true
          }
        })
      }
    },
    updateTreeItem(item) {
      //未修改父级id

      if (this.cuurEditData.pid == item.pid) {
        return this.foreachTreeItem(this.tree, (row, rows) => {
          if (row.value == item.id) {
            var data = Object.assign(
              { label: item.name, value: item.id, disabled: true },
              item
            )
            for (var key in row) {
              if (key != 'children') {
                this.$set(row, key, data[key])
              }
            }
            return true
          }
        })
      } else {
        var row = this.removeTreeItem(this.cuurEditData)
        var data = Object.assign(
          {
            label: item.name,
            value: item.id,
            children: row.children,
            disabled: true
          },
          item
        )
        return this.addTreeItem(data)
      }
    },
    removeTreeItem(item, isRemoveChildren) {
      return this.foreachTreeItem(this.tree, (row, rows) => {
        if (row.value == item.id) {
          if (Array.isArray(row.children) && isRemoveChildren) {
            this.removeChildrenTreeItem(row.children)
          }
          var index = rows.indexOf(row)
          if (index > -1) {
            rows.splice(index, 1)
          }
          return row
        }
      })
    },
    removeChildrenTreeItem(rows) {
      for (var row of rows) {
        if (Array.isArray(row.children)) {
          this.removeChildrenTreeItem(row.children)
        }
        var index = rows.indexOf(row)
        if (index > -1) {
          rows.splice(index, 1)
        }
      }
    },
    cloneItem(item) {
      var obj = {}
      for (var key in item) {
        if (key != 'children') {
          obj[key] = item[key]
        }
      }
      return obj
    },
    getRemoveItems(item) {
      var ids = [item.id]
      var ret = [this.cloneItem(item)]
      if (Array.isArray(item.children)) {
        this.foreachTreeItem(item.children, (row, rows) => {
          if (ids.indexOf(row.pid) > -1) {
            ids.push(row.id)
            ret.push(this.cloneItem(row))
          }
        })
      }
      return ret
    },
    foreachTreeItem(rows, callback) {
      for (var row of rows) {
        var ret = callback(row, rows)
        if (ret) {
          return ret
        } else if (Array.isArray(row.children)) {
          ret = this.foreachTreeItem(row.children, callback)
          if (ret) {
            return ret
          }
        }
      }
    },
    setCheckedKeys(ids) {
      this.$refs.tree.setCheckedKeys(ids)
    }
  },
  watch: {
    project() {
      this.getProjectResource()
    },
    tree(newVal) {
      this.formItems[1].bind.options = newVal
    }
  }
}
</script>
<style lang="less">
.project-resource {
  height: 500px;
  overflow: auto;
  .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
    background-color: #409eff;
    border-color: #409eff;
    &::after {
      border-color: #fff;
    }
  }
}
</style>
