import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    routeKey: 1,
    uploadData: null,
    keepAliveInclude: ['project-manage', 'domain-manage', 'user-manage'],
    menus: require('./menus.js').default
  },
  mutations: {
    routeReload(state) {
      state.routeKey++
    },
    setUploadData(state, payload) {
      state.uploadData = payload
    },
    setMenus(state, menus) {
      state.menus = menus
    }
  },
  getters: {
    langOptions(state) {
      var langOptions = []
      for (var key in state.langs) {
        langOptions.push({
          label: state.langs[key],
          value: key
        })
      }
      return langOptions
    },
    categoryOptions(state) {
      var categoryOptions = []
      for (var key in state.categorypg) {
        categoryOptions.push({
          label: state.categorypg[key],
          value: key
        })
      }
      return categoryOptions
    }
  },
  actions: {},
  modules: {}
})
