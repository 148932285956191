<template>
  <el-cascader
    v-model="val"
    :options="cvtOptions"
    :props="{ checkStrictly: true }"
  ></el-cascader>
</template>
<script>
export default {
  name: 'resource-cascader',
  props: {
    options: Array,
    value: String,
    nid: String, //父级id不能与自身id相同
  },
  data() {
    return {}
  },
  computed: {
    cvtOptions() {
      return [
        {
          label: '最上级',
          value: '0',
          id: '0',
          children: this.deepCopy(this.options, false),
        },
      ]
    },
    val: {
      get() {
        var ids = []
        this.deepIds(this.cvtOptions, this.value, ids)
        return ids
      },
      set(newVal) {
        this.$emit('input', newVal.length > 0 ? newVal[newVal.length - 1] : '')
      },
    },
  },
  methods: {
    deepCopy(rows, parentDisabled) {
      var ret = []
      for (var row of rows) {
        var item = {
          label: row.name,
          value: row.id,
        }
        if (row.id == this.nid || parentDisabled) {
          item.disabled = true
        }
        if (Array.isArray(row.children)) {
          var children = this.deepCopy(row.children, item.disabled)
          if (children && children.length > 0) {
            item.children = children
          }
        }
        ret.push(item)
      }
      return ret
    },
    deepIds(rows, value, values) {
      for (var row of rows) {
        if (row.value == value) {
          values.push(value)
          return true
        } else if (Array.isArray(row.children)) {
          if (this.deepIds(row.children, value, values)) {
            values.unshift(row.value)
            return true
          }
        }
      }
      return false
    },
  },
}
</script>