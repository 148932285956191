<template>
  <div class="project-setting">
    <el-page-header @back="onBack" content="项目设置"></el-page-header>
    <div class="section">
      <base-section label="基本信息"></base-section>
      <div style="margin-left: 40px">
        <project-info :value="project"></project-info>
      </div>
    </div>
    <div class="section-col-wrap">
      <div class="section-col">
        <base-section label="项目资源"
          ><el-button
            icon="el-icon-plus"
            type="text"
            @click="projectResourceBeginAdd"
            >添加资源</el-button
          ></base-section
        >
        <project-resource
          ref="projectResource"
          :project="project"
        ></project-resource>
      </div>
      <div class="section-col">
        <base-section label="项目角色">
          <el-button
            icon="el-icon-plus"
            type="text"
            @click="projectRoleBeginAdd"
            >添加角色</el-button
          >
        </base-section>
        <project-role
          ref="projectRole"
          :project="project"
          @select-role-res="selectRoleRes"
        ></project-role>
      </div>
    </div>
  </div>
</template>
<script>
import ProjectInfo from '../components/ProjectInfo.vue'
export default {
  components: { ProjectInfo },
  name: 'project-setting',
  data() {
    return {
      project: {
        id: this.$route.query.id,
      },
    }
  },
  created() {
    this.getProjectInfo()
  },
  methods: {
    onBack() {
      this.$router.go(-1)
    },
    getProjectInfo() {
      this.$post('/project/find', {
        id: JSON.stringify({
          Field: 'id',
          ComparePattern: 1,
          Values: [this.project.id],
        }),
      }).then((resp) => {
        if (resp.data.code == 0 && resp.data.data.Data.length > 0) {
          this.project = resp.data.data.Data[0]
        } else {
          this.$message(resp.data.msg || '获取项目信息失败')
        }
      })
    },
    projectResourceBeginAdd() {
      this.$refs.projectResource.beginAdd()
    },
    projectRoleBeginAdd() {
      this.$refs.projectRole.beginAdd()
    },
    selectRoleRes(resIds) {
      this.$refs.projectResource.setCheckedKeys(resIds)
    },
  },
}
</script>
<style lang="less">
.project-setting {
  padding: 15px;
  .section-col-wrap {
    display: flex;
    justify-content: space-between;
    .section-col {
      width: 48%;
    }
  }
}
</style>