<template>
  <div style="height: 100%">
    <base-table
      ref="table"
      url="/project/find"
      :toolbar="toolbar"
      :columns="columns"
    ></base-table>
    <base-dialog v-model="showDialog" @ok="save">
      <base-form
        :init-value="false"
        v-model="formData"
        ref="form"
        :rules="rules"
        :form-items="formItems"
      ></base-form>
    </base-dialog>
  </div>
</template>

<script>
export default {
  name: 'project-manage',
  data() {
    return {
      showDialog: false,
      formData: {},
      toolbar: {
        formItems: [
          { label: 'ID', prop: 'id', cp: this.$ComparePatterns.Matchs },
          { label: '名称', prop: 'name', cp: this.$ComparePatterns.Matchs }
        ],
        query: {
          SortFields: JSON.stringify([{ Field: 'cre_time', Order: -1 }])
        }
      },
      columns: [
        { bind: { label: 'ID', prop: 'id', width: 190 } },
        { bind: { label: '名称', prop: 'name' } },
        {
          bind: { label: '登录权限校验', prop: 'check_res', width: 100 },
          default: {
            component: 'cell-text',
            bind: {
              formatter(scope, defaultVal) {
                return defaultVal ? '是' : '否'
              }
            }
          }
        },
        { bind: { label: '域', prop: 'domain', width: 80 } },
        {
          bind: { label: '用户登录身份认证', prop: 'check_login', width: 130 },
          default: {
            component: 'cell-text',
            bind: {
              formatter(scope, defaultVal) {
                return defaultVal==null||defaultVal==0 ? '否' : '是'
              }
            }
          }
        },
        // { bind: { label: '安全密钥', prop: 'secure_key' } },

        { bind: { label: '描述', prop: 'descr' } },
        { bind: { label: '创建时间', prop: 'cre_time', width: 150 } },
        {
          bind: { label: '操作', prop: 'Name', width: 120 },
          header: {
            component: 'cell-button',
            bind: { label: '添加', type: 'primary' },
            on: {
              click: this.beginAdd
            }
          },
          default: {
            component: 'cell-buttons',
            bind: {
              buttons: [
                {
                  label: '修改',
                  type: 'text',
                  click: this.beginEdit
                },
                {
                  label: '删除',
                  type: 'text',
                  click: this.delProject
                },
                {
                  label: '设置',
                  type: 'text',
                  click: (scope) => {
                    this.$router.push({
                      path: '/ProjectSetting',
                      query: { id: scope.row.id }
                    })
                  }
                }
              ]
            }
          }
        }
      ],
      formItems: [
        { label: '项目名称', prop: 'name' },
        {
          label: '项目描述',
          prop: 'descr',
          bind: { type: 'textarea' }
        },
        {
          label: '登录权限校验',
          prop: 'check_res',
          component: 'el-switch'
        },
        {
          label: '用户登录身份验证',
          prop: 'check_login',
          component: 'el-switch'
        }
      ],
      rules: {
        name: [
          { required: true, message: '请输入项目名称', trigger: 'blur' },
          {
            min: 1,
            max: 50,
            message: '项目名称长度在 1 到 50 个字符',
            trigger: 'blur'
          }
        ],
        descr: [
          { required: true, message: '请输入项目描述', trigger: 'blur' },
          {
            min: 1,
            max: 200,
            message: '项目描述长度在 1 到 100 个字符',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  computed: {
    table() {
      return this.$refs.table
    },
    form() {
      return this.$refs.form
    }
  },
  methods: {
    beginAdd() {
      var token = this.$getToken(true)
      if (token) {
        this.formData = {
          name: '',
          descr: '',
          domain: token.domain,
          check_res: false,
          check_login:false
        }
        this.showDialog = true
      }
    },
    beginEdit(scope) {
      var token = this.$getToken(true)
      if (token) {
        this.formData = {
          id: scope.row.id,
          name: scope.row.name,
          domain: scope.row.domain ? scope.row.domain : token.domain,
          descr: scope.row.descr,
          check_res: scope.row.check_res || false,
          check_login: scope.row.check_login || false
        }
        this.showDialog = true
      }
    },
    save() {
      this.form.validate((flag, fields) => {
        if (flag) {
          this.$post('/project/' + (this.formData.id ? 'update' : 'add'), {
            parameters: JSON.stringify(this.formData)
          }).then((resp) => {
            if (resp.data.code == 0) {
              this.showDialog = false
              this.table.refreshData()
            } else {
              this.$message(resp.data.msg || '操作失败')
            }
          })
        } else {
          this.$message('填写有误')
        }
      })
    },
    delProject(scope) {
      if (confirm('确定删除?')) {
        this.$post('/project/remove', {
          parameters: JSON.stringify(scope.row)
        }).then((resp) => {
          if (resp.data.code == 0) {
            this.table.refreshData()
          } else {
            this.$message(resp.data.msg || '删除失败')
          }
        })
      }
    }
  }
}
</script>
