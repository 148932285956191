<template>
  <div style="height: 100%">
    <base-table
      ref="table"
      url="/domain/find"
      :toolbar="toolbar"
      :columns="columns"
    ></base-table>
  </div>
</template>

<script>
export default {
  name: 'domain-manage',
  data() {
    return {
      toolbar: {
        formItems: [
          { label: '名称', prop: 'name', cp: this.$ComparePatterns.Matchs },
        ],
        query: {
          SortFields: JSON.stringify([{ Field: 'reg_time', Order: -1 }]),
        },
      },
      columns: [
        { bind: { label: 'Id', prop: 'id' } },
        { bind: { label: '名称', prop: 'name' } },
        {
          bind: { label: '类型', prop: 'domain_type' },
          default: {
            component: 'cell-text',
            bind: {
              formatter(scope, value) {
                switch (scope.row.domain_type) {
                  case 'C':
                    return '企业'
                  case 'P':
                    return '个人'
                }
                return ''
              },
            },
          },
        },
        { bind: { label: '注册时间', prop: 'reg_time' } },
        { bind: { label: '审核人', prop: 'auditor' } },
        { bind: { label: '审核时间', prop: 'audit_time' } },
        // {
        //   bind: { label: '操作', prop: 'Name', width: 100 },
        //   default: {
        //     component: 'cell-buttons',
        //     bind: {
        //       buttons: [
        //         {
        //           label: '修改',
        //           type: 'text',
        //           click: (scope) => {},
        //         },
        //         {
        //           label: '审核',
        //           type: 'text',
        //           click: (scope) => {},
        //         },
        //       ],
        //     },
        //   },
        // },
      ],
    }
  },
}
</script>