<template>
  <div class="role-user-manage">
    <base-dialog
      title="设置用户"
      v-model="val"
      @ok="val = false"
      @opened="refreshUser"
    >
      <base-table
        ref="table"
        url="/user/findByRole"
        :toolbar="toolbar"
        :columns="columns"
        :init-search="false"
      ></base-table>
    </base-dialog>
    <base-dialog
      title="添加用户"
      v-model="showDialog"
      @ok="showDialog = false"
      @opened="refreshUserView"
      @close="addUserClose"
    >
      <base-table
        ref="tableAddUser"
        url="/user/findByRoleView"
        :toolbar="toolbarAddUser"
        :columns="columnsAddUser"
        :init-search="false"
      ></base-table>
    </base-dialog>
  </div>
</template>
<script>
export default {
  name: 'role-user-manage',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: Boolean,
    role: Object,
    project: Object,
  },
  data() {
    return {
      showDialog: false,
      isUpdateData: false,
      toolbar: {
        formItems: [
          {
            label: '工号',
            prop: 'id',
            cp: this.$ComparePatterns.Matchs,
            bind: { style: 'width: 120px' },
          },
          {
            label: '姓名',
            prop: 'nick_name',
            cp: this.$ComparePatterns.Matchs,
          },
        ],
        query: {
          project: this.project.id,
          role: this.role.id,
          SortFields: JSON.stringify([{ Field: 'id', Order: 1 }]),
        },
      },
      columns: [
        { bind: { label: '工号', prop: 'id' } },
        { bind: { label: '姓名', prop: 'nick_name' } },
        {
          bind: { label: '状态', prop: 'disabled' },
          default: {
            component: 'user-state',
          },
        },
        {
          bind: { label: '操作', prop: 'Name', width: 100 },
          header: {
            component: 'cell-button',
            bind: {
              label: '添加',
              type: 'primary',
              icon: 'el-icon-plus',
              size: 'mini',
            },
            on: {
              click: () => {
                this.showDialog = true
              },
            },
          },
          default: {
            component: 'cell-button',
            bind: { label: '删除', type: 'text', icon: 'el-icon-delete' },
            on: {
              click: this.delUserGroup,
            },
          },
        },
      ],
      toolbarAddUser: {
        formItems: [
          {
            label: '工号',
            prop: 'id',
            cp: this.$ComparePatterns.Matchs,
            bind: { style: 'width: 120px' },
          },
          {
            label: '姓名',
            prop: 'nick_name',
            cp: this.$ComparePatterns.Matchs,
          },
        ],
        query: {
          project: this.project.id,
          role: this.role.id,
          SortFields: JSON.stringify([{ Field: 'id', Order: 1 }]),
        },
      },
      columnsAddUser: [
        { bind: { label: '工号', prop: 'id' } },
        { bind: { label: '姓名', prop: 'nick_name' } },
        {
          bind: { label: '状态', prop: 'disabled' },
          default: {
            component: 'user-state',
          },
        },
        {
          bind: { label: '添加用户', prop: 'added', width: 100 },
          default: {
            component: 'role-add-user',
            bind: {
              project: this.project,
              role: this.role,
            },
            on: {
              updateData: () => {
                this.isUpdateData = true
              },
            },
          },
        },
      ],
    }
  },
  computed: {
    table() {
      return this.$refs.table
    },
    tableAddUser() {
      return this.$refs.tableAddUser
    },
    val: {
      get() {
        return this.value
      },
      set(newVal) {
        this.$emit('change', newVal)
      },
    },
  },
  methods: {
    refreshUser() {
      this.toolbar.query.role = this.role.id
      this.table.refreshData()
    },
    refreshUserView() {
      this.toolbarAddUser.query.role = this.role.id
      this.tableAddUser.refreshData()
    },
    delUserGroup(scope) {
      if (confirm('确定删除?')) {
        this.$post('/ugroup/remove', {
          parameters: JSON.stringify({
            uid: scope.row.id,
            domain: this.project.domain,
            gid: this.role.gid,
          }),
        }).then((resp) => {
          if (resp.data.code == 0) {
            this.table.delRow(scope.row)
          } else {
            this.$message(resp.data.msg || '删除失败')
          }
        })
      }
    },
    addUserClose() {
      this.table.refreshData()
    },
  },
  watch: {
    project(newVal) {
      this.columnsAddUser[3].default.bind.project = newVal
    },
    role(newVal) {
      this.columnsAddUser[3].default.bind.role = newVal
    },
  },
}
</script>
<style lang="less">
.role-user-manage {
  .base-table {
    height: 400px;
    // .toolbar {
    //   padding-left: 10px;
    // }
  }
}
</style>