<template>
  <div class="role-add-user">
    <el-button
      v-if="added"
      type="danger"
      icon="el-icon-delete"
      size="mini"
      :loading="loading"
      @click="del"
      >删除</el-button
    >
    <el-button
      v-else
      type="primary"
      icon="el-icon-plus"
      size="mini"
      :loading="loading"
      @click="add"
      >添加</el-button
    >
  </div>
</template>
<script>
export default {
  name: 'role-add-user',
  props: {
    scope: Object,
    project: Object,
    role: Object,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    added() {
      return this.scope.row.added == true
    },
  },
  methods: {
    add() {
      this.loading = true
      this.$post('/ugroup/add', {
        parameters: JSON.stringify({
          uid: this.scope.row.id,
          domain: this.project.domain,
          gid: this.role.gid,
        }),
      })
        .then((resp) => {
          this.loading = false
          if (resp.data.code == 0) {
            this.scope.row.added = true
            this.$emit('updateData')
          } else {
            this.$message(resp.data.msg || '添加失败')
          }
        })
        .catch((error) => {
          this.$message(error.message)
          this.loading = false
        })
    },
    del() {
      this.loading = true
      this.$post('/ugroup/remove', {
        parameters: JSON.stringify({
          uid: this.scope.row.id,
          domain: this.project.domain,
          gid: this.role.gid,
        }),
      })
        .then((resp) => {
          this.loading = false
          if (resp.data.code == 0) {
            this.scope.row.added = false
            this.$emit('updateData')
          } else {
            this.$message(resp.data.msg || '删除失败')
          }
        })
        .catch((error) => {
          this.$message(error.message)
          this.loading = false
        })
    },
  },
}
</script>