import './assets/index.css'
import Vue from 'vue'
if (window.xtooltech) Vue.use(window.xtooltech.default)

/*导入router*/
import router from './router/index'

/*导入element-ui*/
import './plugins/element'

/*注册自定义组件*/
import './components/index'

import store from './store'

Vue.config.productionTip = false
window.$app = new Vue({
  router,
  store,
  render(h) {
    return h(require('./App.vue').default)
  }
}).$mount('#app')
