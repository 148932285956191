<template>
  <el-tree
    :data="tree"
    :render-content="renderContent"
    class="cus-tree-render"
  ></el-tree>
</template>
<script>
export default {
  name: 'resource-tree',
  methods: {
    deepTreeData(rows, pid) {
      var ret = []
      if (Array.isArray(rows)) {
        for (var row of rows) {
          if (row.pid == pid) {
            var item = Object.assign({ label: row.name, value: row.id }, row)
            var children = this.deepTreeData(rows, item.id)
            if (children && children.length > 0) {
              item.children = children
            }

            ret.push(item)
          }
        }
      }
      return ret
    },
    renderContent(h, { node, data, store }) {
      return h(
        'resource-tree-item',
        { props: { value: data }, on: { edit: this.edit, del: this.del } },
        []
      )
    },
  },
}
</script>