<template>
  <div class="resource-tree-item">
    <div class="item-name">{{ value.name }}</div>
    <div class="oper-btns">
      <el-button
        style="margin-left: 10px"
        icon="el-icon-edit"
        size="mini"
        type="text"
        @click.stop="edit"
        >修改</el-button
      >
      <el-button icon="el-icon-delete" size="mini" type="text" @click.stop="del"
        >删除</el-button
      >
      <el-button
        icon="el-icon-document-copy"
        size="mini"
        type="text"
        @click.stop="copy"
        >复制ID</el-button
      >
      <input
        ref="copytext"
        type="text"
        :value="value.id"
        class="copy-text-control"
      />
    </div>
  </div>
</template>
<script>
export default {
  name: 'resource-tree-item',
  props: {
    value: Object,
  },
  methods: {
    edit() {
      this.$emit('edit', this.value)
    },
    del() {
      this.$emit('del', this.value)
    },
    copy() {
      this.$refs.copytext.select()
      document.execCommand('copy')
      this.$message({
        duration: 1000,
        message: '已复制到剪切板',
        type: 'success',
      })
    },
  },
}
</script>
<style lang="less">
.resource-tree-item {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
  .oper-btns {
    padding-right: 8px;
  }
  .copy-text-control {
    width: 1px;
    height: 1px;
    margin: 0;
    padding: 0;
    border: none;
    opacity: 0;
  }
}
</style>