<template>
  <el-tree
    v-loading="loading"
    class="resource-state-tree"
    ref="tree"
    :data="tree"
    node-key="id"
    check-strictly
    show-checkbox
    default-expand-all
    @check-change="checkChange"
  ></el-tree>
</template>
<script>
export default {
  name: 'resource-state-tree',
  props: {
    value: Array,
    projectid: String,
    role: String
  },
  data() {
    return {
      loading: false,
      tree: [],
      treeIds: []
    }
  },
  methods: {
    getProjectResource() {
      this.$post('/resource/find', {
        PageIndex: 1,
        PageSize: 9999,
        project: JSON.stringify({
          Field: 'project',
          ComparePattern: 1,
          Values: [this.projectid]
        })
      }).then((resp) => {
        if (resp.data.code == 0) {
          this.treeIds = this.getIds(resp.data.data.Data, 'id')
          this.tree = this.deepTreeData(resp.data.data.Data, '0')
          this.getRoleRes()
        } else {
          this.$message(resp.data.msg || '获取项目信息失败')
        }
      })
    },
    getIds(rows, prop) {
      var ids = []
      for (var row of rows) {
        ids.push(row[prop])
      }
      return ids
    },
    deepTreeData(rows, pid) {
      var ret = []
      if (Array.isArray(rows)) {
        for (var row of rows) {
          if (row.pid == pid) {
            var item = Object.assign({ label: row.name, value: row.id }, row)
            var children = this.deepTreeData(rows, item.id)
            if (children && children.length > 0) {
              item.children = children
            }
            ret.push(item)
          }
        }
      }
      return ret
    },
    getRoleRes() {
      if (this.role) {
        this.loading = true
        this.$post('/role/res', {
          id: this.role,
          project: this.projectid
        })
          .then((resp) => {
            this.loading = false
            if (resp.data.code == 0) {
              var resIds = this.getIds(resp.data.data, 'id')
              this.$refs.tree.setCheckedKeys(resIds)
            } else {
              this.$message(resp.data.msg || '获取该角色资源失败')
            }
          })
          .catch((error) => {
            console.error(error)
            this.loading = false
            this.$message('请求发生异常=>' + error)
          })
      } else {
        this.$refs.tree.setCheckedKeys(this.treeIds)
      }
    },
    checkChange() {
      clearTimeout(this.checkChangeTimeout)
      this.checkChangeTimeout = setTimeout(() => {
        var checkids = this.$refs.tree.getCheckedKeys()
        var nress = []
        for (var id of this.treeIds) {
          if (checkids.indexOf(id) == -1) {
            nress.push(id)
          }
        }
        this.$emit('input', nress)
      }, 150)
    }
  }
}
</script>
<style lang="less">
.resource-state-tree {
  max-height: 400px;
  overflow: auto;
}
</style>
