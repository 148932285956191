<template>
  <div class="user-state">
    <span v-if="isSwitch">
      <el-switch v-model="val" :disabled="switchDisable"></el-switch>
      <span v-if="switchDisable" class="el-icon-loading"></span>
    </span>
    <span v-else :class="{ disabled: !val }">{{ val ? '正常' : '禁用' }}</span>
  </div>
</template>
<script>
export default {
  name: 'user-state',
  props: {
    scope: Object,
    type: String,
  },
  data() {
    return {
      switchDisable: false,
    }
  },
  computed: {
    isSwitch() {
      return this.type == 'switch'
    },
    val: {
      get() {
        return !this.$getPropValue(this.scope.row, this.scope.column.property)
      },
      set(newVal) {
        var data = Object.assign({}, this.scope.row)
        data.pwd = null
        data.disabled = !newVal
        this.switchDisable = true
        this.$post('/user/update', {
          parameters: JSON.stringify(data),
        })
          .then((resp) => {
            this.switchDisable = false
            if (resp.data.code == 0) {
              this.$setPropValue(
                this.scope.row,
                this.scope.column.property,
                !newVal
              )
            } else {
              this.$message(resp.data.msg || '操作失败')
            }
          })
          .catch((error) => {
            this.switchDisable = false
            this.$message(error.message)
            console.error(error)
          })
      },
    },
  },
}
</script>
<style lang="less">
.user-state {
  color: #67c23a;
  .disabled {
    color: #f56c6c;
  }
  .el-icon-loading {
    color: #606266;
    font-size: 16px;
    vertical-align: middle;
    margin-left: 2px;
  }
}
</style>