import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)

const vueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    // console.log(this)
    if (this.currentRoute.path == location) {
        this.app.$store.commit('routeReload');
    } else {
        return vueRouterPush.call(this, location);
    }
}

const routes = []
const context = require.context('../pages/', false, /\.(vue)$/)
context.keys().forEach((filename) => {
    // console.log(filename)
    const item = context(filename).default
    var exec = /\.\/(\w+)\.vue$/i.exec(filename)
    if (exec) {
        var name = exec[1]
        var route = item.route || {}
        routes.push(Object.assign({ name, path: '/' + name, props: true, component: () => import(/* webpackChunkName: "pages" */ '../pages/' + name + '.vue') }, route))
    }
})


export default new VueRouter({
    routes: [
        { path: '/', redirect: '/ProjectManage' }
    ].concat(routes)
})
