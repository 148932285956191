<template>
  <base-table
    class="role-state-list"
    ref="table"
    url="/project/role_res"
    :query="query"
    :columns="columns"
    :show-pagination="false"
    :response-handler="responseHandler"
    :init-search="false"
  ></base-table>
</template>
<script>
export default {
  name: 'role-state-list',
  props: {
    value: Array,
    projectid: String,
    resid: String,
  },
  data() {
    return {
      query: {
        id: this.projectid,
        resid: this.resid,
      },
      columns: [
        { bind: { label: '角色名称', prop: 'name' } },
        {
          bind: { label: '启用权限', prop: 'has_res' },
          default: {
            component: 'cell-switch',
            on: {
              change: this.calcValue,
            },
          },
        },
      ],
    }
  },
  methods: {
    responseHandler(resp) {
      var data =
        resp.data.code == 0
          ? {
              rows: resp.data.data,
              total: resp.data.data.length,
            }
          : {
              rows: [],
              total: 0,
            }
      this.calcValue(data.rows)
      return data
    },
    calcValue(rows) {
      if (!Array.isArray(rows)) rows = this.table.rows
      var nroles = []
      for (var row of rows) {
        if (!row.has_res) {
          nroles.push(row.id)
        }
      }
      this.$emit('input', nroles)
    },
    doRefresh() {
      this.query.resid = this.resid
      this.table.refreshData()
    },
  },
  computed: {
    table() {
      return this.$refs.table
    },
  },
}
</script>
<style lang="less">
.role-state-list {
  &.base-table {
    height: 300px;
  }
}
</style>