<template>
  <el-form class="project-info" ref="form" :model="value" size="small" inline>
    <el-form-item
      v-for="(item, index) in formItems"
      :key="index"
      :label="item.label"
      :prop="item.prop"
    >
      <span class="form-val">{{
        item.formatter ? item.formatter(value) : value[item.prop]
      }}</span>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  name: 'project-info',
  props: {
    value: Object
  },
  data() {
    return {
      formItems: [
        { label: '项目ID：', prop: 'id' },
        { label: '安全密钥：', prop: 'secure_key' },
        { label: '项目名称：', prop: 'name' },
        { label: '域：', prop: 'domain' },
        { label: '项目描述：', prop: 'descr' },
        {
          label: '登录权限校验：',
          prop: 'check_res',
          formatter(value) {
            return value.check_res ? '是' : '否'
          }
        }
      ]
    }
  }
}
</script>
<style lang="less">
.project-info {
  .el-form-item {
    width: 45%;
    white-space: nowrap;
    .form-val {
      white-space: normal;
    }
  }
}
</style>
