<template>
  <div class="project-role">
    <base-table
      ref="table"
      url="/project/role"
      :query="query"
      :columns="columns"
      :show-pagination="false"
      :response-handler="responseHandler"
      highlight-current-row
      @row-click="rowClick"
    ></base-table>
    <base-dialog :title="dialogTitle" v-model="showDialog" @ok="saveData">
      <base-form
        :init-value="false"
        v-model="formData"
        ref="form"
        :rules="rules"
        :form-items="formItems"
      ></base-form>
    </base-dialog>
    <role-user-manage
      v-model="roleUserManage.show"
      :role="roleUserManage.role"
      :project="project"
    ></role-user-manage>
  </div>
</template>
<script>
export default {
  name: 'project-role',
  props: {
    project: Object
  },
  computed: {
    table() {
      return this.$refs.table
    },
    form() {
      return this.$refs.form
    }
  },
  data() {
    return {
      roleUserManage: {
        show: false,
        role: {}
      },
      showDialog: false,
      dialogTitle: '添加资源',
      formData: {},
      cuurEditData: {}, //当前修改的项
      formItems: [
        { label: '角色名称', prop: 'name', value: '' },
        {
          label: '角色权限',
          prop: 'nress',
          component: 'resource-state-tree',
          bind: {
            projectid: this.project.id,
            role: '',
            ref: 'resourceStateTree'
          }
        }
      ],
      rules: {
        name: [
          { required: true, message: '请输入角色名称', trigger: 'blur' },
          {
            min: 1,
            max: 25,
            message: '长度在 1 到 25 个字符',
            trigger: 'blur'
          }
        ]
      },
      query: {
        id: this.project.id,
        name: ''
      },
      columns: [
        {
          bind: { label: '角色名称', prop: 'name' },
          header: {
            component: 'cell-input',
            bind: {
              class: 'role-input',
              placeholder: '请输入角色名称',
              clearable: true
            },
            on: {
              input: this.roleInput
            }
          }
        },
        { bind: { label: '创建时间', prop: 'cre_time', width: 150 } },
        {
          bind: { label: '操作', prop: 'Name' },
          default: {
            component: 'cell-buttons',
            bind: {
              buttons: [
                {
                  label: '修改',
                  type: 'text',
                  click: this.beginEdit
                },
                {
                  label: '删除',
                  type: 'text',
                  click: this.delRole
                },
                {
                  label: '设置用户',
                  type: 'text',
                  click: (scope) => {
                    this.roleUserManage.role = scope.row
                    this.roleUserManage.show = true
                  }
                }
              ]
            }
          }
        }
      ]
    }
  },
  methods: {
    responseHandler(resp) {
      this.$emit('select-role-res', [])
      if (resp.data.code == 0) {
        return {
          rows: resp.data.data,
          total: resp.data.data.length
        }
      } else {
        return {
          rows: [],
          total: 0
        }
      }
    },
    roleInput(val) {
      clearTimeout(this.stimeout)
      this.stimeout = setTimeout(() => {
        this.query.name = val
        this.table.refreshData()
      }, 450)
    },
    rowClick(row, column, event) {
      this.$post('/role/res', {
        id: row.id,
        project: this.project.id
      }).then((resp) => {
        if (resp.data.code == 0) {
          var resIds = []
          for (var item of resp.data.data) {
            resIds.push(item.id)
          }
          this.$emit('select-role-res', resIds)
        } else {
          this.$message(resp.data.msg || '获取该角色资源失败')
        }
      })
    },
    beginAdd() {
      this.formItems[1].bind.role = ''
      this.dialogTitle = '添加角色'
      this.showDialog = true
      setTimeout(() => {
        this.formData = {
          domain: this.project.domain,
          project: this.project.id,
          name: '',
          nress: []
        }
        this.$refs.form.$refs.resourceStateTree.getProjectResource() //需要每次触发计算roles字段
      }, 100)
    },
    beginEdit(scope) {
      this.formItems[1].bind.role = scope.row.id

      this.dialogTitle = '修改角色'
      this.showDialog = true
      setTimeout(() => {
        this.cuurEditData = scope.row
        this.formData = Object.assign({ nress: [] }, scope.row)
        this.$refs.form.$refs.resourceStateTree.getProjectResource() //需要每次触发计算roles字段
      }, 100)
    },
    saveData() {
      this.form.validate((flag, fields) => {
        if (flag) {
          var isAdd = !this.formData.id
          this.$post(
            '/role/' + (isAdd ? 'addAndResouce' : 'updateAndResouce'),
            {
              parameters: JSON.stringify(this.formData)
            }
          ).then((resp) => {
            if (resp.data.code == 0) {
              this.showDialog = false
              this.table.refreshData()
              // if (isAdd) {
              //   this.table.addRow(resp.data.data)
              // } else {
              //   this.table.updRow(this.cuurEditData, resp.data.data)
              // }
            } else {
              this.$message(resp.data.msg || '操作失败')
            }
          })
        } else {
          this.$message('请填写完整')
        }
      })
    },
    delRole(scope) {
      if (confirm('确定删除"' + scope.row.name + '"?')) {
        this.$post('/role/remove', {
          parameters: JSON.stringify(scope.row)
        }).then((resp) => {
          if (resp.data.code == 0) {
            this.table.delRow(scope.row)
            this.$emit('select-role-res', [])
          } else {
            this.$message(resp.data.msg || '删除失败')
          }
        })
      }
    }
  }
}
</script>
<style lang="less">
.project-role {
  height: 500px;
  .role-input {
    padding-left: 0;
    padding-right: 0;
    vertical-align: middle;
  }
}
</style>
