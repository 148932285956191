export default [
  {
    text: '域管理',
    icon: 'el-icon-s-claim',
    path: '/DomainManage',
    resid: 'XLNAZVaX2mYZDqyPJgwD'
  },
  {
    text: '项目管理',
    icon: 'el-icon-s-claim',
    path: '/ProjectManage',
    resid: 'YR7xGVyMbzmL7KQmJPlL'
  },
  {
    text: '用户管理',
    icon: 'el-icon-s-claim',
    path: '/UserManage',
    resid: '49zXElpzo8nNRdyeg8jO'
  }
]
