<template>
    <div class="base-section" :class="{ 'bottom-line': bottomLine }">
        <div class="base-section-title"><span class="v-line">|</span><span class="label">{{label}}</span></div>
        <div class="content"><slot></slot></div>
    </div>
</template>
<script>
export default {
    name: 'base-section',
    props: {
        label: String,
        bottomLine: Boolean
    }
}
</script>
<style lang="less" scoped>
.base-section{
    margin: 15px 0px;
    display: flex;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;
    &.bottom-line {
        border-bottom: 1px solid #e5ebf9;
    }
    .base-section-title{
        flex-shrink: 0;
    }
    .content{
        padding-left: 20px;
        flex-grow: 1;
    }
    .label{
        font-size: 15px;
    }
    .v-line{
        color: rgb(90, 80, 255);
        font-size: 18px;
        font-weight: bold;
        margin-right: 6px;
    }
}
</style>