<template>
  <div class="app">
    <left-menu v-if="logged" :menus="menus" :auto-collapse="false"></left-menu>
    <login
      v-else
      @success="loginSuccess"
      url="/signin/login"
      domain="Xtooltech"
      project="R0ABbvpE2xe7b4y9kzEq"
    ></login>
  </div>
</template>

<script>
export default {
  name: 'app',

  data() {
    return {
      logged: false
    }
  },
  computed: {
    menus: {
      get() {
        return this.$store.state.menus
      },
      set(newVal) {
        this.$store.commit('setMenus', newVal)
      }
    }
  },
  created() {
    var token = this.$getToken()
    if (token) this.loginSuccess(token)
  },
  methods: {
    loginSuccess(token) {
      // if (token.id != 'DA') {
      //   localStorage.removeItem('token')
      //   this.$message('权限未开通.')
      //   return
      // }
      this.logged = true
    }
  }
}
</script>

<style>
.app {
  height: 100%;
}
</style>
